<template>
    <div class="tgcss-max-w-[1200px] tgcss-m-auto tgcss-w-full">


        <div class="tgcss-grid tgcss-gap-4 tgcss-grid-cols-8 tgcss-mb-5">
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center tgcss-h-28 hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 tgcss-opacity-30 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto  tgcss-mb-1 tgcss-text-[#f8931d]" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <svg class="tgcss-w-12 tgcss-m-auto  tgcss-mb-1 tgcss-text-[#f8931d]" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                <span class="tgcss-text-sm tgcss-font-semibold tgcss-opacity-85 tgcss-text-[#f8931d]">پیشخوان</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6  tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">قیمت‌ها</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-4 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-sm tgcss-font-semibold tgcss-opacity-85">معامله</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">واریز/برداشت</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">تاریخچه</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">حساب‌ها</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">اعلانات</span>
            </div>
            <div class="card tgcss-flex tgcss-items-center tgcss-justify-center hover:tgcss-bg-gray-100 tgcss-text-center tgcss-py-6 tgcss-h-28 tgcss-rounded-md tgcss-cursor-pointer tgcss-relative tgcss-overflow-hidden">
                <svg class="tgcss-w-16 -tgcss-bottom-4 -tgcss-right-3 tgcss-absolute tgcss-m-auto tgcss-mb-1 tgcss-opacity-10" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" stroke-linecap="round" stroke-linejoin="round"></path>   <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <svg class="tgcss-w-12 tgcss-m-auto tgcss-mb-1 tgcss-opacity-50" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" stroke-linecap="round" stroke-linejoin="round"></path>   <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
                <span class="tgcss-text-xs tgcss-font-semibold tgcss-opacity-85">تنظیمات</span>
            </div>
        </div>

           
        <div class="tgcss-grid tgcss-gap-4 tgcss-grid-cols-3  tgcss-mb-5">
            <div class="card"> 
                <div class="card-body">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-6">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">دارایی‌ها</div>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-bg-white tgcss-p-0.5 tgcss-px-2 tgcss-pb-1">تومان</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">USDT</div>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-sm tgcss-text-gray-700">کیف‌پول : </div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center">
                            <span class="tgcss-text-base">174,794,064</span>
                            <span class="tgcss-mr-1 tgcss-text-xs tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-mt-1 tgcss-mb-4">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-xss tgcss-text-gray-700 tgcss-opacity-80">تغییر در ۲۴ ساعت</div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center tgcss-text-red-400">
                            <span class="tgcss-text-xss">1,774,035-</span>
                            <span class="tgcss-mr-1 tgcss-text-xss tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-start tgcss-h-[74px]">
                        <img src="https://api.wallex.ir/coins/BTC/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/TRX/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/USDT/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/BNB/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <span class="tgcss-bg-gray-100 tgcss-text-gray-600 tgcss-flex tgcss-items-center tgcss-justify-center tgcss-h-7 tgcss-w-7 tgcss-font-semibold tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full">+12</span>
                    </div>
                    <div class="tgcss-w-full tgcss-grid tgcss-gap-3 tgcss-grid-cols-2 tgcss-font-semibold tgcss-text-sm tgcss-mt-5">
                        <router-link :to="{ name: 'ExchangeWalletDeposit' }" :data-target="'ExchangeWalletDeposit'"  class="!tgcss-bg-[#f8931d] !tgcss-text-center !tgcss-no-underline !tgcss-text-white tgcss-font-bold !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            واریز
                        </router-link>
                        <router-link :to="{ name: 'ExchangeWalletWithdraw' }" :data-target="'ExchangeWalletWithdraw'"  class="!tgcss-bg-[#f4f5f7] !tgcss-text-center !tgcss-no-underline hover:!tgcss-bg-[#f8931d] !tgcss-text-gray-700 !tgcss-font-semibold hover:!tgcss-text-white !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            برداشت
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-6">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">کارمزد معامله‌ها</div>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-bg-white tgcss-p-0.5 tgcss-px-2 tgcss-pb-1">تومان</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">USDT</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">BTC</div>
                        </div>
                    </div>
                    <div class="tgcss-bg-gray-100 tgcss-rounded-md tgcss-pt-[14px] tgcss-pb-[15px] tgcss-px-4 tgcss-mb-11">
                        <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-xs tgcss-text-gray-700">میزان کارمزد شما : </div>
                        <div class="tgcss-font-semibold tgcss-text-xs tgcss-flex tgcss-items-center" dir="ltr">
                            %0.2
                        </div>
                    </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-sm tgcss-text-gray-700">حجم معاملات ۳۰ روزه شما : </div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center">
                            <div class="tgcss-text-base">14,794,064</div>
                            <div class="tgcss-mr-1 tgcss-text-xs tgcss-opacity-70">تومان</div>
                        </div>
                    </div>
                    <div>
                        <div class="tgcss-flex tgcss-items-center tgcss-justify-between tgcss-mt-3 ">
                            <div class="tgcss-text-slate-500 dark:tgcss-text-zink-200 tgcss-mb-1"> 14,794,064 تومان </div>
                            <div class="tgcss-mb-0 tgcss-text-slate-500">14.7%</div>
                        </div>
                        <div class="tgcss-w-full tgcss-bg-slate-200 tgcss-rounded-full tgcss-h-2.54 dark:tgcss-bg-zink-600">
                            <div class="tgcss-bg-[#f8931d] tgcss-h-2.5 tgcss-rounded-full" style="width: 14.7%"></div>
                        </div>
                        <div class="tgcss-text-xs tgcss-font-normal tgcss-text-slate-400 tgcss-text-justify tgcss-py-2">
                            سطح کارمزد معاملات بر اساس حجم معامله‌های ۳۰ روز گذشته شما در هر پایه بازار محاسبه می‌شود.
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body tgcss-divide-y tgcss-divide-gray-100">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-3">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">اطلاعیه‌ها</div>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">مشاهده همه</div>
                        </div>
                    </div>
                    <a href="#" class="tgcss-flex tgcss-gap-3 tgcss-group tgcss-items-center tgcss-py-2 !tgcss-no-underline">
                        <div class="tgcss-w-14 tgcss-h-14 tgcss-flex-none tgcss-overflow-hidden tgcss-rounded-lg tgcss-relative">
                            <img src="https://snow-asp-mvc.netlify.app/html/assets/images/product-1.png" class="" alt="images">
                        </div>
                        <div class="tgcss-flex-1">
                            <div class="tgcss-flex tgcss-justify-between tgcss-items-center tgcss-gap-3">
                                <p class="tgcss-line-clamp-2 tgcss-text-xs tgcss-font-semibold !tgcss-mb-1 tgcss-text-gray-600 group-hover:tgcss-text-[#f8931d]">  توقف پشتیبانی از USDC بر روی شبکه ترون (TRC20)</p>
                            </div>
                            <div class="tgcss-flex tgcss-justify-between tgcss-gap-3">
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">تغییر در شبکه‌ها</span>
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">2 ماه پیش</span>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="tgcss-flex tgcss-gap-3 tgcss-group tgcss-items-center tgcss-py-2 !tgcss-no-underline">
                        <div class="tgcss-w-14 tgcss-h-14 tgcss-flex-none tgcss-overflow-hidden tgcss-rounded-lg tgcss-relative">
                            <img src="https://snow-asp-mvc.netlify.app/html/assets/images/product-1.png" class="" alt="images">
                        </div>
                        <div class="tgcss-flex-1">
                            <div class="tgcss-flex tgcss-justify-between tgcss-items-center tgcss-gap-3">
                                <p class="tgcss-line-clamp-2 tgcss-text-xs tgcss-font-semibold !tgcss-mb-1 tgcss-text-gray-600 group-hover:tgcss-text-[#f8931d]">امکان واریز و برداشت تمامی توکن‌ها روی شبکه‌های اتریوم، آربیتروم و پالیگان </p>
                            </div>
                            <div class="tgcss-flex tgcss-justify-between tgcss-gap-3">
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">تغییر در شبکه‌ها</span>
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">2 ماه پیش</span>
                            </div>
                        </div>
                    </a>
                    <a href="#" class="tgcss-flex tgcss-gap-3 tgcss-group tgcss-items-center tgcss-py-2 !tgcss-no-underline">
                        <div class="tgcss-w-14 tgcss-h-14 tgcss-flex-none tgcss-overflow-hidden tgcss-rounded-lg tgcss-relative">
                            <img src="https://snow-asp-mvc.netlify.app/html/assets/images/product-1.png" class="" alt="images">
                        </div>
                        <div class="tgcss-flex-1">
                            <div class="tgcss-flex tgcss-justify-between tgcss-items-center tgcss-gap-3">
                                <p class="tgcss-line-clamp-2 tgcss-text-xs tgcss-font-semibold !tgcss-mb-1 tgcss-text-gray-600 group-hover:tgcss-text-[#f8931d]">بازار معاملاتی تمامی توکن‌ها فعال بوده و عملیات آن تغییری نخواهد داشت.</p>
                            </div>
                            <div class="tgcss-flex tgcss-justify-between tgcss-gap-3">
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">تغییر در شبکه‌ها</span>
                                <span class="tgcss-text-xss tgcss-text-black/40 dark:tgcss-text-white/40">2 ماه پیش</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="card">
           <div class="card-body !tgcss-pb-0">
                <div class="tgcss-flex tgcss-items-center tgcss-mb-5">
                    <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">بازارهای معاملاتی</div>
                    <label class=" tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                        <svg class="tgcss-w-6 tgcss-mr-1" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <input type="text" v-model="search" placeholder="جستجوی بازار ..." class="!tgcss-bg-transparent !tgcss-border-none !tgcss-shadow-none ">
                    </label>
                    <div class="tgcss-items-center tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-text-sm tgcss-mr-3 tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                        <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">همه</div>
                    </div>
                </div>

                <div class="-tgcss-mx-5 tgcss-overflow-x-auto">
                    <table class="tgcss-w-full tgcss-whitespace-nowrap !tgcss-border-none !tgcss-bg-transparent">
                        <thead class="ltr:tgcss-text-left rtl:tgcss-text-right">
                            <tr>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pr-6 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">بازار</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">آخرین قیمت</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden sm:tgcss-table-cell">تغییرات (24h) </th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">حجم معاملات (24h)</th>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pl-[20px]  tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 text-center tgcss-hidden md:tgcss-table-cell">عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredItems" v-bind:key="index" class="hover:tgcss-bg-slate-100 tgcss-group">
                                <td class="tgcss-px-3.5 tgcss-py-3 tgcss-pr-[18px] tgcss-border-y tgcss-border-gray-200 ">
                                    <div class="tgcss-flex tgcss-gap-2 tgcss-items-center">
                                        <svg class="tgcss-w-6 tgcss-ml-2 tgcss-opacity-50 hover:tgcss-opacity-100 tgcss-cursor-pointer" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <div class="tgcss-bg-green-100 tgcss-rounded-full tgcss-size-7 tgcss-shrink-0">
                                            <img :src="'https://api.wallex.ir/coins/'+item.symbol+'/icon/svg'" alt="" class="tgcss-h-7 tgcss-rounded-full">
                                        </div>
                                        <a href="#" class="grow !tgcss-no-underline">
                                            <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-base tgcss-flex tgcss-items-center !tgcss-text-gray-700">{{item.nameFa}}<span class="tgcss-hidden md:tgcss-inline-block tgcss-opacity-50">/{{item.pTypeFa}}</span></h3>
                                            <div class="tgcss-text-gray-500 tgcss-font-medium tgcss-text-xss tgcss-mb-1">{{item.symbol}}<span class="tgcss-opacity-50 tgcss-hidden md:tgcss-inline-block">/{{item.pType}}</span></div>
                                        </a>
                                    </div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700">
                                    <div class="">{{item.pt}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.p}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                    <div class="">2,253,366</div>
                                    <div class="tgcss-inline-flex tgcss-items-center  tgcss-font-semibold text-right" :class="item.changeLevel == 'high' ? 'tgcss-text-green-500' : 'tgcss-text-red-500'"  dir="ltr">
                                        <svg class="tgcss-w-4 tgcss-mr-1" :class="item.changeLevel == 'low' ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m4.5 15.75 7.5-7.5 7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        {{item.change}}

                                    </div>   
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                    <div class="">{{item.changeTotalFa}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.changeTotal}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 tgcss-pl-[20px] tgcss-text-sm tgcss-text-gray-700 tgcss-font-semibold tgcss-max-w-20 text-left tgcss-hidden md:tgcss-table-cell">
                                    <button class="tgcss-bg-[#f4f5f7] hover:tgcss-bg-[#f8931d] group group-hover:tgcss-bg-[#f8931d] group-hover:tgcss-text-white tgcss-text-gray-700 hover:tgcss-text-white tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-px-4 !tgcss-rounded-md tgcss-w-full"> معامله </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>

    </div>
</template>


<script>

export default {
    name: 'TradeDashboard',
    components: {},
    data: function () {
        return {
            search:'',
            List:[
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"ریال",
                    "symbol":"Rial",
                    "pType":"Rial",
                    "pTypeFa":"ریال",
                    "deposit": "450,000",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"32,500",
                    "changeTotalFa":"450,000",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":false,
                    }
                },
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "0.12",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"20,794,000",
                    "changeTotalFa":"90,017,302",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"2",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "2.7",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"253,366",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"2,420,000",
                    "changeTotalFa":"12,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"3",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "415",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"1,366",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"94,372",
                    "changeTotalFa":"7,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"4",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "170",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0,06",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"4,372",
                    "changeTotalFa":"302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                }
                
                
            ],
        }
    },
    computed: {
        filteredItems() {
            return this.List.filter(item => {
                return item.nameFa.toLowerCase().includes(this.search.toLowerCase()) || 
                item.name.toLowerCase().includes(this.search.toLowerCase()) || 
                item.pType.toLowerCase().includes(this.search.toLowerCase()) || 
                item.symbol.toLowerCase().includes(this.search.toLowerCase()) 
            })
        }
     },
    mounted() {
    }
}

</script>
    